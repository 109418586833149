import React from "react";
import { graphql, useStaticQuery } from 'gatsby';
import { getBrText } from "../../helpers/helper.rendering";
import imageAbout3 from '../../images/3.jpg'
import imageAbout1 from '../../images/1.jpg'


const History = () => {
    const { realEstate } = useStaticQuery(graphql`
     query {
         realEstate {
             name
             sections{
                 about{
                    items{
                        data{
                            id
                            value
                        }
                        images
                    }
                 }

             }
         }
     }`)
    return (
        <section className="about-history">
            <div className="container-fluid" id="checkContainer">
                <div className="row content-history">
                    <div className="col-12 text-center">     
                        <div className="row article-history">
                            <div data-aos="fade-right" className="col-12 d-lg-flex align-items-strech">
                                <div className="row">
                                    <div className="col-lg-6 col-12 offset-lg-0 order-lg-1 order-2">
                                        {/* <img src={realEstate.sections.about.items.images[0]} alt={realEstate?.name} /> */}
                                        <img src={imageAbout3} alt="nosotros historia" />
                                    </div>
                                    <div className="col-lg-6 ps-lg-5 text-start order-lg-2 order-1 d-flex flex-column justify-content-evenly">
                                        <div className="d-flex align-items-center">
                                            <h1 className="me-5">01</h1>
                                            <p className="text-start">
                                                {getBrText(realEstate.sections.about.items.data[0].value).map(element => (
                                                    <>
                                                        {element}
                                                        <br className="d-none d-lg-block" />
                                                    </>
                                                ))}
                                            </p>
                                        </div>
                                        <div className="d-flex align-items-center">
                                        <h1 className="me-5">02</h1>
                                        <p className="text-start">
                                            {getBrText(realEstate.sections.about.items.data[1].value).map(element => (
                                                <>
                                                    {element}
                                                    <br className="d-none d-lg-block" />
                                                </>
                                            ))}
                                        </p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row article-history">
                            <div data-aos="fade-left" className="col-12 d-lg-flex align-items-strech">
                                <div className="row">
                                    <div className="col-lg-6 col-10 offset-2 offset-lg-0 text-start order-1 d-flex flex-column justify-content-evenly">
                                    <div className="d-flex align-items-center">
                                        <h1 className="me-5" >03</h1>
                                        <p className="text-start">
                                            {getBrText(realEstate.sections.about.items.data[2].value).map(element => (
                                                <>
                                                    {element}
                                                    <br className="d-none d-lg-block" />
                                                </>
                                            ))}
                                        </p>

                                    </div>

                                    <div className="d-flex align-items-center">
                                        <h1 className="me-5">04</h1>
                                        <p className="text-start">
                                            {getBrText(realEstate.sections.about.items.data[3].value).map(element => (
                                                <>
                                                    {element}
                                                    <br className="d-none d-lg-block" />
                                                </>
                                            ))}
                                        </p>

                                    </div>

                                    </div>
                                    <div className="col-lg-6 col-12 mb-5 mb-lg-0 ps-lg-5 order-2">
                                        {/* <img src={realEstate.sections.about.items.images[1]} alt={realEstate?.name} /> */}
                                        <img src={imageAbout1} alt="nosotros historia" />
                                    </div>                     
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default History